<template>
  <div class="pageBox">
    <div class="content-block">
      <form-card title="修改密码">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="100px"
          class="formBox"
          @validate="formValidate"
        >
          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="邮箱" prop="email">
                <!-- 禁止浏览器自动填充密码，勿删-->
                <input type="password" style="position:absolute;width:0;height:0;z-index: -999" />
                <el-input
                  v-inputInserted="'email'"
                  aria-label="邮箱"
                  aria-autocomplete="email"
                  clearable
                  placeholder="请输入邮箱地址"
                  type="text"
                  v-model="form.email"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="旧密码" prop="oldPassword">
                <el-input
                  aria-label="旧密码"
                  aria-autocomplete="none"
                  clearable
                  type="password"
                  v-model="form.oldPassword"
                  placeholder="请填写旧密码，需同时包含大小写字母和数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="新密码" prop="newPassword">
                <el-input
                  aria-label="新密码"
                  aria-autocomplete="none"
                  clearable
                  type="password"
                  v-model="form.newPassword"
                  placeholder="请填写新密码，需同时包含大小写字母和数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="margin-b10">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="确认新密码" prop="newPasswordConfirm">
                <el-input
                  aria-label="确认新密码"
                  aria-autocomplete="none"
                  clearable
                  type="password"
                  v-model="form.newPasswordConfirm"
                  placeholder="请再次填写新密码，需同时包含大小写字母和数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="margin-b10 imgCaptchaBox">
            <el-col :span="12" :offset="4" :xs="{span:14,offset:0}">
              <el-form-item label="图片验证码" prop="imgCaptcha">
                <el-input
                  aria-label="图片验证码"
                  aria-autocomplete="none"
                  clearable
                  maxlength="4"
                  aria-valuemax="4"
                  show-word-limit
                  placeholder="请输入图片中的验证码"
                  v-model="form.imgCaptcha"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" :offset="1" :xs="{span:7}">
              <img class="verifiImg" :src="imgCaptchaInfo.img" alt="图片验证码" />
              <div role="button" class="refresh cursor" @click="getImgCaptcha()">点击刷新</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item class="text-center">
                <div
                  class="btn"
                  :class="{btnDisabled:Object.keys(formValid).length != Object.keys(rules).length}"
                  @click="Object.keys(formValid).length == Object.keys(rules).length && submitForm('form')"
                >提 交</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </form-card>
    </div>
  </div>
</template>

<script>
import { passWord } from "@/utils/RegExp.js";
import { singleAccountSever } from "@/common/api-config";
import FormCard from "@/components/FormCard.vue";

export default {
  components: { FormCard },
  created() {
    this.getImgCaptcha(); //获取验证码
  },
  data() {
    //新密码
    var newPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else {
        if (this.form.confirmPassWord !== "") {
          this.$refs.form.validateField("newPasswordConfirm");
        }
        callback();
      }
    };
    //2次确定密码
    var newPasswordConfirm = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    //验证码检验
    var checkImgCaptcha = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入图形中的验证码"));
      } else if (value.length == 4) {
        singleAccountSever
          .checkImgCaptcha({
            imgCaptcha: value,
            ckey: this.imgCaptchaInfo.key
          })
          .then(result => {
            if (result.code == 0) {
              callback();
            } else if (result.code == 1019) {
              // 图片验证码过期，需要重新获取图片验证码
              this.getImgCaptcha();
              callback(new Error("图形验证码已失效"));
            } else {
              callback(new Error("图形验证码输入错误"));
            }
          });
      } else {
        callback(new Error("图片验证码错误"));
      }
    };
    return {
      imgCaptchaInfo: {
        //图片验证码数据
        key: "",
        img: ""
      },
      form: {
        //表单
        email: this.$store.getters["accountStore/accountInfo"].email,
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        imgCaptcha: ""
      },
      formValid: {}, //用于检验form是否通过
      rules: {
        //表单验证规则
        email: [
          { required: true, message: "请输入合法的邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入合法的邮箱地址", trigger: "blur" }
        ],
        oldPassword: [
          { required: true, validator: passWord, trigger: ["blur", "change"] }
        ],
        newPassword: [
          { required: true, validator: passWord, trigger: ["blur", "change"] },
          { required: true, validator: newPassword, trigger: "blur" }
        ],
        newPasswordConfirm: [
          {
            required: true,
            validator: newPasswordConfirm,
            trigger: ["blur", "change"]
          }
        ],
        imgCaptcha: [
          {
            required: true,
            validator: checkImgCaptcha,
            trigger: ["change"]
          }
        ]
      }
    };
  },
  methods: {
    //检查每个input是否通过
    formValidate(key, valid) {
      if (valid) {
        this.$set(this.formValid, key, valid);
      } else {
        this.$delete(this.formValid, key);
      }
    },
    //提交
    submitForm() {
      singleAccountSever
        .modifyPwd({
          email: this.form.email, //	当前邮箱
          oldPassword: this.$md5(this.form.oldPassword + this.configBaseSALT), //旧密码
          password: this.$md5(this.form.newPassword + this.configBaseSALT), //新密码
          ckey: this.imgCaptchaInfo.key, //图形验证码key
          imgCaptcha: this.form.imgCaptcha //图形验证码
        })
        .then(result => {
          if (result.code == 0) {
            localStorage.clear();
            this.$router.push({ path: "/account/login" });
          } else if (result.code == 1019) {
            this.getImgCaptcha();
          } else {
            this.$message.error(result.message);
          }
        });
    },
    //获取图片验证码
    getImgCaptcha() {
      singleAccountSever.getImgCaptcha().then(result => {
        if (result.code == 0) {
          this.imgCaptchaInfo = result.data[0].captcha; //图片验证码数据
        } else if (result.code == 1019) {
          this.getImgCaptcha();
        } else {
          this.$message.error("获取验证图片失败");
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: rgba(40, 38, 44, 1);
  box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  cursor: pointer;
}
.btnDisabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.pageBox {
  padding: 60px 0px 70px 0px;
  height: 100%;
  background-color: #f8f6f7;
  .formBox {
    margin: auto;
    .imgCaptchaBox {
      .verifiImg {
        width: 100%;
        display: block;
        height: 36px;
        border-radius: 4px;
      }
      .refresh {
        line-height: 30px;
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>